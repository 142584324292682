<template>
<span>
  <b-form-input
    :inline="true"
    type="number"
    :min="min"
    :max="max"
    :step="step"
    v-model="modelValue"
    :id="id"
    :name="id"
    class="number-input"
    :class="{ 'no-arrows': !arrows, }"
    :disabled="disabled"
    :required="required"
    :style="numberStyle"
    :placeholder="placeholder"
    @wheel="$event.target.blur()"
    @keydown="filterNumbers($event)"
    @focusout="onFocusOut()"
  >
  </b-form-input>
  <span v-if="arrows" class="arrows">
    <a href @click.prevent="incValue"><i class="fa fa-chevron-up"></i></a>
    <a href @click.prevent="decValue" ><i class="fa fa-chevron-down"></i></a>
  </span>
</span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'NumberInput',
  prop: ['value'],
  props: {
    value: [Number, String],
    id: String,
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 1000000,
    },
    step: {
      type: [String, Number],
      default: '0.01',
    },
    inputStyle: {
      type: Object,
      default: null,
    },
    arrows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValue: '',
    }
  },
  computed: {
    numberStyle() {
      let style = {}
      if (this.inputStyle) {
        style = this.inputStyle
      }
      if (this.arrows) {
        style.display = 'inline-block'
        style.width = '80%'
        style.verticalAlign = 'top'
      }
      return style
    },
  },
  watch: {
    value: function() {
      this.modelValue = this.value
    },
    modelValue: function() {
      const modelValue = +this.modelValue
      this.$emit('input', modelValue)
      this.$emit('change', modelValue)
    },
    placeholder: function() {},
    disabled: function() {},
  },
  methods: {
    filterNumbers: function(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode === 38 || charCode === 40) {
        // ignore flèches haut et bas qui incrémente ou décrémente le nombre
        evt.preventDefault()
        evt.stopPropagation()
      }
      return true
    },
    onFocusOut: function() {
      this.$emit('focusout', {})
    },
    incValue() {
      if (!this.disabled && (this.modelValue < this.max)) {
        this.modelValue += 1
      }
    },
    decValue() {
      if (!this.disabled && (this.modelValue > this.min)) {
        this.modelValue -= 1
      }
    },
  },
  mounted() {
    this.modelValue = this.value
  },
}
</script>
<style lang="less">
input.number-input::-webkit-outer-spin-button,
input.number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input.number-input[type=number] {
    -moz-appearance: textfield;
}
.arrows {
  font-size: 9px;
  display: inline-block;
  width: 20%;
  a {
    display: block
  }
}
</style>
