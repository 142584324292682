<template>
  <div class="member-by_activity-stats">
    <page-header
      :title="showEvents ? 'Nombre de places par événements' : 'Membres par activités'"
      icon="fas fa-chart-bar"
      :links="getLinks()"
    >
    </page-header>
    <div>
      <b-row>
        <b-col cols="10" ref="printMe">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <div class="empty-text"  v-if="elements.length === 0">Aucune données correspondantes</div>
          <div v-show="!isLoading(statsLoading)" ref="excelMe">
            <table class="table table-responsive table-bordered">
              <tr v-if="elements.length">
                <td class="header-cell cell-odd"></td>
                <td
                  v-for="year of displayedSchoolYears" :key="year.id"
                  class="text-center header-cell"
                  :class="yearClass(year)"
                  :colspan="groups.length"
                >
                  {{ year.name }}
                </td>
              </tr>
              <tr v-if="elements.length && groups.length > 1">
                <td class="header-cell cell-even"></td>
                <td
                  v-for="elt of groupsAndYears" :key="elt.key"
                  class="text-center header-cell group-cell"
                  :class="headerClass(elt)"
                >
                  {{ elt.group.name }}
                </td>
              </tr>
              <tr v-if="elements.length && !showEvents">
                <td class="header-cell">
                  Nombre d'inscriptions
                </td>
                <td
                  v-for="elt of columns" :key="elt.key"
                  class="text-center header-cell"
                  :class="headerClass(elt)"
                >
                  {{ headerSum(elt) }}
                  <div v-if="diff && getTotalDiff(elt)" class="ignore">
                    <div class="diff">{{ getTotalDiff(elt) }}</div>
                  </div>
                </td>
              </tr>
              <tr v-for="(element, rowIndex) of elements" :key="element">
                <td class="text-left" :class="rowIndex === 0 ? 'header-cell': 'first-cell '">
                  <span v-if="element">{{ element }}</span>
                  <span v-else class="empty-cell">Non défini</span>
                </td>
                <td
                  v-for="cell of getElementRow(element)"
                  :key="cell.index"
                  class="number text-center"
                  :class="cellClas(cell, rowIndex)"
                >
                  {{ getCellValue(cell, rowIndex, element) }}
                  <div v-if="diff && getDiff(element, cell)" class="ignore">
                    <div class="diff">{{ getDiff(element, cell) }}</div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
        <b-col cols="2">
          <div>
            <b>Afficher en pourcentage</b>
            <br />
            <b-form-checkbox name="showPercentage" id="showPercentage" v-model="showPercentage">
              pour la colonne
            </b-form-checkbox>
            <b-form-checkbox
              name="showPercentage2" id="showPercentage2" v-model="showPercentage2"
              :disabled="groups.length === 1"
            >
              pour la ligne
            </b-form-checkbox>
          </div>
          <br />
          <b-form-checkbox name="diff" id="diff" v-model="forceDiff" :disabled="selectedSchoolYears.length <= 1">
            Affichage évolution
          </b-form-checkbox>
          <br /><br />
          <active-school-years-multi-select v-model="selectedSchoolYears"></active-school-years-multi-select>
          <br /><br />
          <b-form-group
            label="Afficher par"
            label-for="activitiesBy"
            description=""
          >
            <activities-by-select v-model="showBy" :include-events="!youth"></activities-by-select>
          </b-form-group>
          <b-form-group
            label="Grouper par"
            label-for="groupBy"
            description=""
          >
            <b-form-select
              id="group-by"
              v-model="selectedGroupBy"
              @change="selectedGroupByChanged()"
            >
              <b-form-select-option :value="elt.id" v-for="elt in groupBys" :key="elt.id">
                {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <div v-if="selectedGroupBy && selectedGroupBy === 12" class="custom-range-holder no-print">
            <div><b>Tranches de QF</b></div>
            <custom-range-input id="family-level" v-model="familyLevels"></custom-range-input>
          </div>
          <div v-if="selectedGroupBy && selectedGroupBy === 10" class="custom-range-holder no-print">
            <div><b>Groupes d'âge</b></div>
            <custom-range-input id="age-group" v-model="ageGroups"></custom-range-input>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ActiveSchoolYearsMultiSelect from '@/components/SchoolYears/ActiveSchoolYearsMultiSelect.vue'
import ActivitiesBySelect from '@/components/Stats/ActivtiesBySelect.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { existsIn } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { compareNumbers } from '@/utils/sorting'
import CustomRangeInput from '@/components/Controls/CustomRangeInput'

export default {
  name: 'members-by-activity',
  mixins: [BackendMixin],
  components: {
    CustomRangeInput,
    ActiveSchoolYearsMultiSelect,
    ActivitiesBySelect,
    LoadingGif,
    PageHeader,
  },
  props: {
    youth: [String, Number],
  },
  data() {
    return {
      statsLoading: 'members-by-activity',
      selectedSchoolYears: [],
      statsByYear: new Map(),
      elementsMap: new Map(),
      rows: [],
      sums: [],
      showPercentage: false,
      showPercentage2: false,
      forceDiff: true,
      showBy: null,
      events: false,
      groupBy: '',
      groupByValues: [],
      selectedGroupBy: 0,
      groupBys: [
        makeChoice({ id: 0, name: 'Ne pas grouper', }),
        makeChoice({ id: 10, name: 'Groupe d\'age', }),
        makeChoice({ id: 2, name: 'Villes', }),
        makeChoice({ id: 3, name: 'Quartiers', }),
        makeChoice({ id: 11, name: 'Mixité', }),
        makeChoice({ id: 12, name: 'Quotient familial', }),
        makeChoice({ id: 13, name: 'Prix sélectionné', })
      ],
      groups: [],
      familyLevels: [],
      ageGroups: [],
    }
  },
  computed: {
    diff() {
      return this.selectedSchoolYears.length > 1 && this.forceDiff
    },
    displayedSchoolYears() {
      const schoolYears = [].concat(this.selectedSchoolYears)
      return schoolYears.sort((a, b) => compareNumbers(a.startYear, b.startYear))
    },
    groupsAndYears() {
      const elements = []
      for (const schoolYear of this.displayedSchoolYears) {
        for (const group of this.groups) {
          elements.push(
            {
              year: schoolYear,
              group: group,
              key: '' + schoolYear.id + ':' + group.id,
            }
          )
        }
      }
      return elements
    },
    elements() {
      return Array.from(this.elementsMap.keys())
    },
    showEvents() {
      return this.showBy && this.showBy.id > 20
    },
    columns() {
      let items = []
      for (const year of this.displayedSchoolYears) {
        for (const group of this.groups) {
          items.push(
            {
              year: year,
              group: group,
              key: '' + year.id + ':' + group.id,
            }
          )
        }
      }
      return items
    },
  },
  async mounted() {
    await this.onLoaded()
    await this.loadFamilyLevels()
    await this.loadAgeGroups()
  },
  watch: {
    selectedSchoolYears: function() {
      this.onLoaded()
    },
    showBy: function() {
      this.onLoaded()
    },
    events: function() {
      this.onLoaded()
    },
    ageGroups: function() {
      this.groups = []
      this.onLoaded()
    },
    familyLevels: function() {
      this.groups = []
      this.onLoaded()
    },
    showPercentage() {
      if (this.showPercentage && this.showPercentage2) {
        this.showPercentage2 = false
      }
    },
    showPercentage2() {
      if (this.showPercentage && this.showPercentage2) {
        this.showPercentage = false
      }
    },
    groups() {
      if (this.showPercentage2 && this.groups.length === 1) {
        this.showPercentage2 = false
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadStats()
    },
    async loadStats() {
      if (this.showBy) {
        this.startLoading(this.loadingName)
        const statsByYear = new Map()
        for (const schoolYear of this.selectedSchoolYears) {
          let url = '/stats/api/members-by-activities/' + this.youth + '/' + this.showBy.id + '/' + schoolYear.id + '/'
          if (this.selectedGroupBy) {
            url += '?group_by=' + this.selectedGroupBy
          }
          if (this.selectedGroupBy === 10) {
            url += '&age_groups=' + this.ageGroups
          }
          if (this.selectedGroupBy === 12) {
            url += '&family_levels=' + this.familyLevels
          }
          const backendApi = new BackendApi('get', url)
          try {
            const resp = await backendApi.callApi()
            const groups = resp.data.groups.map(elt => makeChoice(elt))
            const existingIds = this.groups.map(elt => elt.id)
            for (const group of groups) {
              if (!existsIn([group.id], existingIds)) {
                this.groups.push(group)
              }
            }
            statsByYear.set(schoolYear.id, resp.data.inscriptions)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
        this.statsByYear = statsByYear
        this.buildRows()
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'membres-par-activite-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const diff = this.forceDiff
      this.forceDiff = false
      const that = this
      setTimeout(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = 'membres-par-activite-' + moment().format('YYYY-MM-DD')
          const docContent = that.$refs.excelMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await that.addError(this.getErrorText(err))
          }
          that.forceDiff = diff
        },
        200
      )
    },
    getElementRow(elt) {
      const rowIndex = this.elementsMap.get(elt)
      const groups = this.rows[rowIndex].groups
      let items = []
      for (let yearIndex = 0; yearIndex < this.displayedSchoolYears.length; yearIndex++) {
        for (const group of this.groups) {
          const groupIndex = groups.map(group => group.id).indexOf(group.id)
          try {
            const subItems = this.rows[rowIndex].groups[groupIndex].years
            items.push(
              {
                value: subItems[yearIndex],
                groupIndex: groupIndex,
                yearIndex: yearIndex,
              }
            )
          } catch (err) {
          }
        }
      }
      return items.map((elt, index) => {
        return { ...elt, index: index, }
      })
    },
    buildRows() {
      const elementsMap = new Map()
      const rows = []
      let row
      const sums = []
      for (let groupIndex = 0; groupIndex < this.groups.length; groupIndex++) {
        const group = this.groups[groupIndex]
        sums.push(
          {
            id: group.id,
            name: group.name,
            years: new Array(this.displayedSchoolYears.length).fill(0),
          }
        )
      }
      for (let yearIndex = 0; yearIndex < this.displayedSchoolYears.length; yearIndex++) {
        const year = this.displayedSchoolYears[yearIndex]
        const stats = this.statsByYear.get(year.id)
        if (stats) {
          for (const elt of stats) {
            let years
            const element = elt.name || ''
            if (!elementsMap.has(element)) {
              elementsMap.set(element, rows.length)
              row = {
                label: element,
                groups: this.groups.map(
                  group => {
                    return {
                      id: group.id,
                      name: group.name,
                      years: new Array(this.displayedSchoolYears.length).fill(0),
                    }
                  }
                ),
              }
              rows.push(row)
            } else {
              const rowIndex = elementsMap.get(element)
              row = rows[rowIndex]
            }
            const group = elt.group || 0
            const groupIndex = row.groups.map(elt => elt.id).indexOf(group)
            try {
              row.groups[groupIndex].years[yearIndex] = elt.count || 0
            } catch (err) {
            }
          }
        }
        for (let groupIndex = 0; groupIndex < this.groups.length; groupIndex++) {
          const group = this.groups[groupIndex]
          sums[groupIndex].years[yearIndex] = sum(
            rows.filter((cell, index) => index > 0).map(row => row.groups[groupIndex].years[yearIndex])
          )
        }
      }
      this.sums = sums
      this.rows = rows
      this.elementsMap = elementsMap
    },
    yearClass(year) {
      const yearIndex = this.displayedSchoolYears.map(elt => elt.id).indexOf(year.id) + 1
      return (yearIndex % 2) ? 'cell-odd' : 'cell-odd2'
    },
    headerClass(elt) {
      const groupIndex = this.groups.map(itm => itm.id).indexOf(elt.group.id)
      const yearIndex = this.displayedSchoolYears.map(itm => itm.id).indexOf(elt.year.id)
      let cssClass
      if (yearIndex % 2) {
        cssClass = (groupIndex % 2) ? 'cell-odd2' : 'cell-even2'
      } else {
        cssClass = (groupIndex % 2) ? 'cell-odd' : 'cell-even'
      }
      cssClass += ' header-cell'
      return cssClass
    },
    cellClas(cell, rowIndex) {
      let groupIndex = Math.floor(cell.index / this.groups.length)
      let cssClass
      if (groupIndex % 2) {
        cssClass = (cell.index % 2) ? 'cell-odd2' : 'cell-even2'
      } else {
        cssClass = (cell.index % 2) ? 'cell-odd' : 'cell-even'
      }
      if (rowIndex === 0) {
        cssClass += ' header-cell'
      } else {
        cssClass += ' cell'
      }
      return cssClass
    },
    headerSum(elt) {
      const groupIndex = this.groups.map(itm => itm.id).indexOf(elt.group.id)
      const yearIndex = this.displayedSchoolYears.map(itm => itm.id).indexOf(elt.year.id)
      try {
        const cellValue = this.sums[groupIndex].years[yearIndex]
        if (this.showPercentage2) {
          const total2 = sum(this.sums.map(elt => elt.years[yearIndex]))
          if (total2) {
            const value = Math.round(10000 * cellValue / total2) / 100
            return '' + value + '%'
          }
          return ''
        } else {
          return cellValue
        }
      } catch (err) {
        return ''
      }
    },
    getCellValue(cell, rowIndex, element) {
      if (this.showPercentage && rowIndex > 0) {
        const total = this.sums[cell.groupIndex].years[cell.yearIndex]
        if (total) {
          const value = Math.round(10000 * cell.value / total) / 100
          return '' + value + '%'
        }
        return ''
      }
      if (this.showPercentage2) {
        const total2 = sum(this.getElementRow(element).map(elt => elt.value))
        if (total2) {
          const value = Math.round(10000 * cell.value / total2) / 100
          return '' + value + '%'
        }
        return ''
      }
      if (cell.value) {
        return cell.value
      }
      return ''
    },
    getTotalDiff(elt) {
      const groupIndex = this.groups.map(itm => itm.id).indexOf(elt.group.id)
      const yearIndex = this.displayedSchoolYears.map(itm => itm.id).indexOf(elt.year.id)
      if (yearIndex > 0) {
        try {
          const value = this.sums[groupIndex].years[yearIndex]
          const prevValue = this.sums[groupIndex].years[yearIndex - 1]
          if (prevValue) {
            const diff = Math.round(1000 * (value - prevValue) / prevValue) / 10
            const prefix = (diff > 0) ? '+' : ''
            return prefix + diff + '%'
          }
        } catch (err) {
        }
      }
      return ''
    },
    getDiff(element, cell) {
      const row = this.getElementRow(element)
      if (cell.yearIndex > 0) {
        const prevIndex = cell.groupIndex + (this.groups.length * (cell.yearIndex - 1))
        try {
          const prevValue = row[prevIndex].value
          if (cell.value && prevValue) {
            const diff = Math.round(1000 * (cell.value - prevValue) / prevValue) / 10
            const prefix = (diff > 0) ? '+' : ''
            return prefix + diff + '%'
          }
        } catch (err) {
        }
      }
      return ''
    },
    selectedGroupByChanged() {
      this.groups = []
      this.loadStats()
    },
    async loadFamilyLevels() {
      const backendApi = new BackendApi('get', '/stats/api/family-level-range/')
      const defaultLevels = [800, 1200, 1600]
      try {
        const resp = await backendApi.callApi()
        if (resp.data.length) {
          this.familyLevels = resp.data.map(elt => elt.level)
        } else {
          this.familyLevels = defaultLevels
        }
      } catch (err) {
        this.familyLevels = defaultLevels
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAgeGroups() {
      let url = '/stats/api/age-groups/'
      let defaultGroups
      if (this.youth) {
        defaultGroups = [3, 6, 11]
      } else {
        url += '?youth=0'
        defaultGroups = [18, 25, 45, 62]
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        if (resp.data.length) {
          this.ageGroups = resp.data.map(elt => elt.start_age)
        } else {
          this.ageGroups = defaultGroups
        }
      } catch (err) {
        this.ageGroups = defaultGroups
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
td.group-cell {
  font-size: 10px;
  max-width: 42px;
  overflow: hidden;
}
table {
  font-size: 13px;
}
.number {
  text-align: right;
}
tr td.header-cell {
  background: #444;
  color: #fff;
}
tr td.header-cell.cell-even {
 background: #444;
}
tr td.header-cell.cell-odd {
 background: #222;
}
tr td.header-cell.cell-even2 {
 background: #585f6e;
}
tr td.header-cell.cell-odd2 {
 background: #333740;
}

tr:nth-of-type(even) td.header-cell {
  background: #555;
}
tr:nth-of-type(even) td.header-cell.cell-even {
 background: #555;
}
tr:nth-of-type(even) td.header-cell.cell-odd {
 background: #333;
}
tr:nth-of-type(even) td.header-cell.cell-even2 {
 background: #414651;
}
tr:nth-of-type(even) td.header-cell.cell-odd2 {
 background: #121316;
}

tr:nth-of-type(odd) {
  background: #eee;
}
tr:nth-of-type(even) {
  background: #fff;
}
tr:nth-of-type(odd) .first-cell {
  background: #aaa;
}
tr:nth-of-type(even) .first-cell {
  background: #bbb;
}

tr:nth-of-type(odd) .cell-odd {
  background: #ccc;
}
tr:nth-of-type(even) .cell-odd {
  background: #ddd;
}
tr:nth-of-type(odd) .cell-odd2 {
  background: #aac;
}
tr:nth-of-type(even) .cell-odd2 {
  background: #aad;
}
tr:nth-of-type(odd) .cell-even {
  background: #eee;
}
tr:nth-of-type(even) .cell-even {
  background: #fff;
}
tr:nth-of-type(odd) .cell-even2 {
  background: #cce;
}
tr:nth-of-type(even) .cell-even2 {
  background: #ccf;
}
tr:hover td.first-cell {
  background: #d4b54d !important;
}
tr:hover td.cell.cell-odd {
  background: #e6e087 !important;
}
tr:hover td.cell.cell-even {
  background: #fffaa5 !important;
}
tr:hover td.cell.cell-odd2 {
  background: #b0d7a8 !important;
}
tr:hover td.cell.cell-even2 {
  background: #d0fcc7 !important;
}

.diff {
  font-size: 10px;
  background: #111;
  color: #fff;
  display: inline-block;
  padding: 1px 2px;
  border-radius: 5px;
}
.empty-cell {
  font-style: italic;
}
</style>
