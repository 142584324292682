<template>
  <span>
    <b-select v-model="selectedChoice" :disabled="choices.length <= 1" @change="onChange">
      <b-select-option v-for="choice of choices" :key="choice.id" :value="choice">
        {{ choice.name }}
      </b-select-option>
    </b-select>
  </span>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'

export default {
  name: 'activities-by-select',
  mixins: [BackendMixin],
  components: {
  },
  prop: ['value'],
  props: {
    value: Object,
    includeEvents: Boolean,
  },
  data() {
    return {
      selectedChoice: null,
    }
  },
  mounted() {
    this.selectedChoice = this.choices[0]
    this.onChange()
  },
  computed: {
    choices() {
      let choices = []
      if (this.includeEvents) {
        choices = [
          makeChoice({ id: 11, name: 'Activité', }),
          makeChoice({ id: 12, name: 'Type d\'activité', }),
          makeChoice({ id: 13, name: 'Compte analytique activités', }),
          makeChoice({ id: 21, name: 'Événements', }),
          makeChoice({ id: 22, name: 'Type d\'événement', }),
          makeChoice({ id: 23, name: 'Compte analytique événéments', })
        ]
      } else {
        choices = [
          makeChoice({ id: 1, name: 'Activité', }),
          makeChoice({ id: 2, name: 'Type d\'activité', }),
          makeChoice({ id: 3, name: 'Compte analytique', })
        ]
      }
      return choices
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onChange() {
      this.$emit('input', this.selectedChoice)
    },
  },
}
</script>

<style lang="less">
</style>
