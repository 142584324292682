<template>
  <div class="custom-range-input">
    <div v-for="elt of values" :key="elt.id" class="range-item">
      <b-form-group>
        <number-input
          :id="id + elt.id"
          v-model="elt.value"
          @focusout="onNumberChanged(elt)"
        ></number-input>
        <div class="range-item-remove">
          <a href @click.prevent="removeItem(elt)">
            <i class="fa fa-remove"></i> supprimer
          </a>
        </div>
      </b-form-group>
    </div>
    <div class="range-item-add">
      <a href @click.prevent="addItem">
        <i class="fa fa-plus"></i>
      </a>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import NumberInput from '@/components/Controls/NumberInput.vue'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'CustomRangeInput',
  components: { NumberInput, },
  prop: ['value'],
  props: {
    value: Array,
    id: {
      type: String,
      default: 'custom-range',
    },
  },
  watch: {
    value() {
      this.init()
    },
  },
  data() {
    return {
      values: [],
    }
  },
  methods: {
    init() {
      this.values = this.value.map((elt, index) => { return { id: index, value: elt, } })
    },
    sortValues() {
      return this.values.sort((eltA, eltB) => compareNumbers(eltA.value, eltB.value))
    },
    onNumberChanged(elt) {
      this.onValueChanged()
    },
    onValueChanged() {
      this.values = this.sortValues()
      this.$emit('input', this.values.map(elt => elt.value))
    },
    addItem() {
      this.values.push({ id: this.values.length, value: 0, })
      this.onValueChanged()
    },
    removeItem(elt) {
      const index = this.values.map(item => item.id).indexOf(elt.id)
      if (index >= 0) {
        this.values.splice(index, 1)
        this.values = this.values.map((elt, index) => { return { id: index, value: elt.value, } })
        this.onValueChanged()
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
.custom-range-input {
  display: block;
}
.range-item {
  display: inline-block;
  width: 100px;
  margin-right: 5px;
  height: auto;
}
.range-item-add {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.range-item-add a {
  color: #888 !important;
}
.range-item-remove a{
  color: #888 !important;
  font-size: 10px;
  margin-top: -2px;
  padding-right: 5px;
  display: block;
  text-align: right;
}
</style>
